  @font-face {
      font-family: 'Gotham-rounded-900';
      font-style: normal;
      font-weight: 900;
      src: url("../../gothamfonts/Gotham-Font/Gotham-Black.otf");
  }
  
  @font-face {
      font-family: 'Gotham-rounded-700';
      font-style: normal;
      font-weight: 900;
      src: url("../../gothamfonts/Gotham-Font/GothamBold.ttf");
  }
  
  @font-face {
      font-family: 'Gotham-rounded-400';
      font-style: normal;
      font-weight: 900;
      src: url("../../gothamfonts/Gotham-Font/GothamLight.ttf");
  }
  
  body {
      font-family: 'Barlow', sans-serif !important;
  }
  
  @media(max-width:1100px) {
      html,
      body {
          min-width: auto !important;
          overflow-y: auto !important;
          overflow: -moz-scrollbars-vertical !important;
      }
      @-moz-document url-prefix() {
          html,
          body {
              min-width: auto !important;
              overflow-y: auto !important;
              overflow: auto !important;
              height: auto !important;
          }
      }
  }
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 07 - SIMPLE BLOCK STRUCTURE */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  input[type="password"], input[type="number"], input[type="tel"], input[type="text"], input[type="email"], select, textarea, .box select {
    background: #f8f8f8;
    border: 0px;
    height: 43px;
    line-height: 43px;
    padding: 0px 15px;
    color: #777777;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 45%;
    margin: 4px 2%;
    border: 1px transparent solid;
    font-family: 'Gotham-rounded-500', sans-serif;
}

.flexformr {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    /* background-image: url(../images/angles/002.png); */
    border-radius: 0.3rem;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
    /* padding-bottom: 0; */
    overflow-y: auto;
    overflow-x: hidden;
}

  .color-green {
      color: #144E82;
  }

  .fontweight {
    font-weight: bold;
  }

  .margintopbottom {
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: larger;
  }

  .blocks-container {
      position: relative;
      width: 100%;
  }
  
  .block {
      position: relative;
      width: 100%;
  }

  .type-1-text {
      height: 100%;
      position: relative;
  }
  .boldfont {
      font-weight: 700;
      font-size: 55px;
  }
  
  .type-1-text p {
      color: #dddddd;
  }
  
  .type-1-text .row {
      height: 100%;
  }
  
  .type-1-text .type-1-center {
      text-align: center;
      height: 100%;
      display: table;
      vertical-align: middle;
  }
  
  .type-1-text .button {
      margin: 0px 15px;
      margin-bottom: 60px;
  }

  #content-wrapper .parallax-bg-text * {
      color: #fff;
  }
  
  #content-wrapper .parallax-bg-text p,
  #content-wrapper .parallax-bg-text ul li,
  #content-wrapper .parallax-bg-text .page-tagline .description {
      color: rgba(255, 255, 255, 0.8);
  }
  
  @media (max-width: 991px) {
      .type-1-text .button {
          margin-bottom: 10px;
      }
      .type-1-text {
          padding-top: 60px;
      }
  }
  
  @media (max-width: 767px) {
      .type-1-text p {
          padding-bottom: 20px;
      }
      .type-1-text .type-1-center {
          overflow: hidden;
          left: 2px !important;
      }
  }
  
  @keyframes magic-move {
      0% {
          margin-top: 0px;
      }
      14% {
          margin-top: 0px;
      }
      28% {
          margin-top: -412px;
      }
      42% {
          margin-top: -412px;
      }
      56% {
          margin-top: -824px;
      }
      70% {
          margin-top: -824px;
      }
      84% {
          margin-top: 0px;
      }
      100% {
          margin-top: 0px;
      }
  }
  
  @-webkit-keyframes magic-move {
      0% {
          margin-top: 0px;
      }
      14% {
          margin-top: 0px;
      }
      28% {
          margin-top: -412px;
      }
      42% {
          margin-top: -412px;
      }
      56% {
          margin-top: -824px;
      }
      70% {
          margin-top: -824px;
      }
      84% {
          margin-top: 0px;
      }
      100% {
          margin-top: 0px;
      }
  }
  /*background*/
  
  .bg {
      position: fixed;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-position: 50% 0px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      position: fixed;
      background-attachment: scroll;
  }
  
  .bg img {
      position: absolute;
  }
  
  .bg video {
      position: absolute;
  }
 
  .mobdisplay {
      display: none;
  }
  .ipaddisplay {
    display: none;
}
  
  .compdisplay {
      display: flex;
  }
  
  @media(max-width:600px) {
      .mobdisplay {
          display: block;
      }
      .compdisplay {
          display: none;
      }
  }
  
  @media screen and (min-width: 602px) and (max-width: 1098px) {
      .mobdisplay {
          display: block;
      }
      .compdisplay {
          display: none;
      }
      .ipaddisplay {
          display: block;
      }

    .videopad {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: unset;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1180px) {
   
    .videocomp {
        clip: rect(auto, auto, auto, auto);
        height: auto;
        position: absolute;
        width: 100%;
        left: 0px;
        top: 0px;
    } 
    
}
  
  .videocomp {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /* height: 100%; */
    width: 100%;
    /* object-fit: unset; */
}
  
  @media screen and (max-width: 600px) {
      .videomob {
          object-fit: cover;
          width: 100vw;
          height: 100vh;
          position: fixed;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
      }
  }
  
  .fontsize {
      font-family: "Gotham-rounded-900", sans-serif;
  }
  
  .clip {
      clip: rect(auto, auto, auto, auto);
      height: 100vh;
      position: fixed;
      width: 100%;
      left: 0px;
      top: 0px;
  }
  
  .type-1-text {
      height: 100%;
      position: relative;
  }
  
  .type-1-text h1,
  .type-1-text h2 {
      color: #fff;
  }
  
  .type-1-text p {
      color: #dddddd;
  }
  
  .type-1-text .row {
      height: 100%;
  }
  
  .type-1-text .type-1-center {
      text-align: center;
      height: 100%;
      display: table;
      vertical-align: middle;
  }
  
  .type-1-text .button {
      margin: 0px 15px;
      margin-bottom: 60px;
  }
  
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 04 - GLOBAL SETTINGS */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /*buttons*/
  
  .button {
      background: #144E82;
      border-radius: 4px;
      height: 48px;
      width: 130px;
      font-size: 16px;
      font-weight: 500;
      -webkit-transition: all 200ms linear !important;
      transition: all 200ms linear !important;
      padding: 0 35px;
      letter-spacing: 0.2px;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center;
      text-align: center;
      border: none;
      color: white;
  }

  .loginfrom {
    font-family: "JosefinSans-600", sans-serif;
    margin: 0 0 10px 0;
    cursor: pointer;
  }
  .loginfrom a {
    text-decoration: underline;
  }
  
  .loginbutton {
      color: #fff;
      font-family: "JosefinSans-600", sans-serif;
      display: inline-block;
      text-align: center;
      background-color: #144E82;
      border: 1.5px solid #144E82;
      height: 45px;
      width: 130px;
      background-image: linear-gradient(to right, #fff, #fff 50%, #144E82 50%);
      background-size: 210% 100%;
      background-position: 99%;
  }
  /*buttons*/
  
  .buttonagenda {
      color: #fff;
      font-family: "JosefinSans-600", sans-serif;
      display: inline-block;
      text-align: center;
      background-color: #144E82;
      border: 1.5px solid #144E82;
      height: 45px;
      width: 230px;
      border-radius: 5px;
      text-transform: uppercase;
      background-image: linear-gradient(to right, #fff, #fff 50%, #144E82 50%);
      background-size: 210% 100%;
      background-position: 99%;
  }
  
  .buttonagenda:active,
  .buttonagenda:focus,
  .buttonagenda:hover {
      transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
      background-position: 0%;
      color: #144E82;
  }
  
  .loginbutton:hover {
      transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
      background-position: 0%;
      color: #144E82;
  }

  .button.type-1 {
      background: #fff;
      color: #144E82;
      text-shadow: none;
      box-shadow: none;
  }
  
  .button.type-1:after {
      border: 2px #144E82 solid;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: none;
  }
  
  .button span {
      position: relative;
      float: left;
      z-index: 1;
  }
  
  .button:hover {
      color: #fff;
  }
  
  .button.type-1:hover {
      background: #144E82;
  }
  
  .button:hover:after {
      width: 100%;
      background: rgba(0, 0, 0, 0.02);
  }
  
  .button.black {
      background: #222222 !important;
      color: #144E82;
  }
  
  .button.black:hover {
      color: #fff;
  }
  
  .button.black:after {
      background: #000;
  }
  
  .play {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -35px;
      margin-top: -35px;
      width: 70px;
      height: 70px;
      border: 3px solid #fff;
      border-radius: 100px;
      z-index: 1;
  }
  
  .play:after {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -5px;
      margin-top: -10px;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-left: 15px solid #144E82;
      border-bottom: 10px solid transparent;
  }
  
  .play:hover {
      background: #144E82;
      border: 3px solid #144E82;
      transform: rotate(-90deg) scale(0.9);
      -o-transform: rotate(-90deg) scale(0.9);
      -moz-transform: rotate(-90deg) scale(0.9);
      -ms-transform: rotate(-90deg) scale(0.9);
      -webkit-transform: rotate(-90deg) scale(0.9);
  }
  
  .play:hover:after {
      border-left: 15px solid #fff;
  }
  /*content wrapper*/
  
  #content-wrapper {
      padding-top: 0px;
      position: relative;
      overflow: hidden;
      width: 100%;
  }
  /*typography*/
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      margin: 0px !important;
  }

  /*form styles*/
  
  form {
      position: relative;
      float: left;
      width: 100%;
  }
  
  .inputclass {
      background: #f8f8f8;
      border: 0px;
      height: 43px;
      line-height: 43px;
      padding: 0px 15px;
      color: #777777;
      font-size: 14px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: 45%;
      margin: 4px 2%;
      border: 1px transparent solid;
  }

   ::-webkit-input-placeholder {
      color: #777777;
  }
  
   :-moz-placeholder {
      color: #777777;
      opacity: 1;
  }
  
   ::-moz-placeholder {
      color: #777777;
      opacity: 1;
  }
  
   :-ms-input-placeholder {
      color: #777777;
  }
  
  .submit-wraper {
      position: relative;
      float: left;
      width: 100%;
      text-align: center;
      margin-top: 32px;
  }
  
  form input[type="submit"] {
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0;
      width: 100%;
      height: 100%;
      border: 0px;
      z-index: 2;
  }

  @media (max-width: 991px) {
      .mob-hide {
          display: none;
      }
      .bg {
          position: fixed;
      }
  }
  
  @media (max-width: 767px) {
      .h1,
      .h2 {
          font-size: 40px;
          margin-bottom: 20px !important;
      }
      .button span {
          width: 100%;
          text-align: center;
      }
      input[type="password"],
      input[type="text"],
      input[type="email"],
      select {
          width: 94%;
      }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 05 - HEADER */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  
  header {
      position: fixed;
      left: 0px;
      top: 0px;
      width: 100%;
      z-index: 11;
      -webkit-backface-visibility: hidden;
  }
  
  header.act {
      background: #fff;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  }

  .mob-icon {
      display: none;
  }

  /*header type-2 (attached to bottom)*/
  
  @media (min-width: 1200px) {
      body {
          overflow-x: hidden;
      }
      header.bottom-fixed {
          top: 100vh;
          position: absolute;
          margin-top: -75px;
      }
      header.bottom-fixed ul ul {
          top: auto !important;
          bottom: 69px;
      }
      header.default-act {
          -moz-transition: none;
          -o-transition: none;
          -webkit-transition: none;
          transition: none;
          -ms-transition: none;
      }
  }
  
  @media (max-width: 1100px) {
      .mob-icon {
          display: block;
          position: relative;
          float: right;
          margin-right: 20px;
          height: 40px;
          cursor: pointer;
      }
      .mob-icon span {
          position: relative;
          float: left;
          width: 40px;
          height: 4px;
          background: #144E82;
          margin-top: 34px;
      }
      .mob-icon span:before {
          position: absolute;
          left: 0px;
          top: -8px;
          content: "";
          width: 40px;
          height: 4px;
          background: #144E82;
      }
      .mob-icon span:after {
          position: absolute;
          left: 0px;
          top: 8px;
          content: "";
          width: 40px;
          height: 4px;
          background: #144E82;
      }
      .mob-icon.act span {
          width: 30px;
      }
      .mob-icon.act span:before {
          width: 30px;
          top: -2px;
      }
      .mob-icon.act span:after {
          width: 30px;
          top: 2px;
      }
      header {
          background: #fff;
          height: 65px;
      }
      header.act-mob {
          height: 100%;
      }
  }
  
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 07 - SIMPLE BLOCK STRUCTURE */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  
  .blocks-container {
      position: relative;
      width: 100%;
  }
  
  .block {
      position: relative;
      width: 100%;
  }
  
  .type-1-text {
      height: 100%;
      position: relative;
  }
  
  .type-1-text h1,
  .type-1-text h2 {
      color: #fff;
  }
  
  .type-1-text p {
      color: #dddddd;
  }
  
  .type-1-text .row {
      height: 100%;
  }
  
  .type-1-text .type-1-center {
      text-align: center;
      height: 100%;
      display: table;
      vertical-align: middle;
  }
  
  .type-1-text .button {
      margin: 0px 15px;
      margin-bottom: 60px;
  }
  
  #content-wrapper .parallax-bg-text * {
      color: #fff;
  }
  
  #content-wrapper .parallax-bg-text p,
  #content-wrapper .parallax-bg-text ul li,
  #content-wrapper .parallax-bg-text .page-tagline .description {
      color: rgba(255, 255, 255, 0.8);
  }

  @media (max-width: 991px) {
      .type-1-text .button {
          margin-bottom: 10px;
      }
      .type-1-text {
          padding-top: 50px;
      }
  }
  
  @media (max-width: 767px) {
      .type-1-text p {
          padding-bottom: 20px;
      }
      .type-1-text .type-1-center {
          overflow: hidden;
      }
  }
  
  @keyframes magic-move {
      0% {
          margin-top: 0px;
      }
      14% {
          margin-top: 0px;
      }
      28% {
          margin-top: -412px;
      }
      42% {
          margin-top: -412px;
      }
      56% {
          margin-top: -824px;
      }
      70% {
          margin-top: -824px;
      }
      84% {
          margin-top: 0px;
      }
      100% {
          margin-top: 0px;
      }
  }
  
  @-webkit-keyframes magic-move {
      0% {
          margin-top: 0px;
      }
      14% {
          margin-top: 0px;
      }
      28% {
          margin-top: -412px;
      }
      42% {
          margin-top: -412px;
      }
      56% {
          margin-top: -824px;
      }
      70% {
          margin-top: -824px;
      }
      84% {
          margin-top: 0px;
      }
      100% {
          margin-top: 0px;
      }
  }

  .w-200px {
    width: 200px !important;
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 30 - THEME CONFIG */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
    
  .flex--form {
      margin-top: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 30px;
      background-position: center;
      background-repeat: no-repeat;
      padding: 20px;
      padding-bottom: 0;
  }
    
  .flex--form--r {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 30px;
      background-position: center;
      background-repeat: no-repeat;
      padding: 20px;
      padding-bottom: 0;
  }
  
  @media(max-width:600px) {
      .flex--form {
          margin-top: 15px;
          margin-bottom: 15px;
      }
  }
  
  @media screen and (min-device-width: 768px) and (max-device-width: 991px) { 
    .flex--form {
        transform: translateX(40%);
    }    
    }

  @media(max-width:990px) {
      .flex--form {
          margin-top: 15px;
          margin-bottom: 15px;
      }
  }
  
  @media(min-width:1500px) {
      .flex--form {
          margin-top: 250px;
      }
  }
  
  .margintopsection {
      margin-top: 130px;
  }
  
  @media(min-width:1500px) {
      .margintopsection {
          margin-top: 150px;
      }
  }
  
  @media only screen and (min-device-width: 820px) and (max-device-height: 1180px) and (orientation:portrait) {
    .mobdisplay {
        display: block !important;
    }
    .ipaddisplay {
        display: none !important;
    }
    .bg video {
        position: absolute;
        object-fit: unset;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-height: 1024px) and (orientation:portrait) {
    .mobdisplay {
        display: block !important;
    }
    .ipaddisplay {
        display: none !important;
    }
    .bg video {
        position: absolute;
        object-fit: unset;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
    }
  }

  @media only screen and (device-width: 1180px) and (device-height: 820px) and (orientation:landscape) {
    .videocomp {
        object-fit: fill;
        height: 100%;
    }
  }

  @media only screen and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {
    .mobdisplay {
        display: block !important;
    }
    .ipaddisplay {
        display: none !important;
    }
    .bg video {
        position: absolute;
        object-fit: unset;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
    }
  }


  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
      .margintopsection {
          margin-top: 100px;
      }
      .flex--form {
          margin-top: 200px;
      }
  }
  
  .eventDate {
      font-family: "Gotham-rounded-700", sans-serif;

  }
  
  .margintoplogin {
    margin-top: 25px !important;
    margin-bottom: 20px;
}

  .paddingbottomzero {
      padding-bottom: 0 !important;
      font-family: "Gotham-rounded-400", sans-serif;

  }
  
  @media(max-width:990px) {
      .margintopsection {
          margin-top: 100px;
      }
  }
  
  @media(max-width:600px) {
    .margintopsection {
        margin-top: 85px;
    }
}

  .logoposition {
      position: relative;
      top: 20px;
      left: 20px;
      z-index: 1;
  }
  
  .chromewidth {
      width: 330px;
      margin-top: 25px;
  }
  
  @media(max-width:600px) {
      .chromewidth {
          width: 100%;
      }
  }
  
  input[type="email"]:focus,
  input[type="password"]:focus {
      outline: 1px solid #144E82 !important;
  }

@keyframes pulsating {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}