#not__authorized{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
}

#portraitModeOnly {
    display: none;
}

@media only screen and (orientation: portrait) {
    #expoViewer {
        /* opacity: 0.3; */
        pointer-events: none;
    }
    body {
        overflow-y: hidden;
    }
    .overlayPotraitMode {
        height: 100vh;
    }
    #portraitModeOnly {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 2800;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        text-align: center;
        font-size: xx-large;
        background: rgba(0, 0, 0, 0.8);
    }
    #portraitModeOnly img {
        width: 2em;
        margin-bottom: 0.8em;
    }
    #portraitModeOnly p {
        color: #FFFFFF;
        font-size: 0.8em;
    }
}

@media only screen and (orientation: landscape) {
    #portraitModeOnly {
        display: none;
    }
}
