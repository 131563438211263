.notice{
  position:absolute ;
  bottom:5vh ;
  width:100% ;
  text-align:center ;
  font-size:20px;
}


@keyframes load {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg);
  }
}

#loadingText {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes borderScale {
  0% {
    border: 5px solid white;
  }
  50% {
    border: 25px solid white;
  }
  100% {
    border: 5px solid white;
  }
}

@keyframes borderScale {
  0% {
    border: 5px solid white;
  }
  50% {
    border: 25px solid white;
  }
  100% {
    border: 5px solid white;
  }
}

/*-------------------------------------------------------------------------------------------------------------------------------*/

/* 02 - LOADER */

/*-------------------------------------------------------------------------------------------------------------------------------*/

#loader-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 12000;
  overflow: hidden;
  background: white;
}

#loader-wrapper.act {
  background: none;
}

#loader-wrapper img {
  z-index: 1;
  width: 180px;
}

#loader-wrapper span {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -140px;
  margin-top: -140px;
  content: "";
  width: 280px;
  height: 280px;
  border-radius: 40px;
  border: 3px solid #144E82;
  -webkit-animation: magic-mouse1 3s infinite ease;
  animation: magic-mouse1 3s infinite ease;
}

@keyframes magic-mouse {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(0.5) rotate(90deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

@-webkit-keyframes magic-mouse {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
  }
  50% {
    -webkit-transform: scale(0.5) rotate(90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0deg);
  }
}

@keyframes magic-mouse1 {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(0.5) rotate(-90deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

@-webkit-keyframes magic-mouse1 {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
  }
  50% {
    -webkit-transform: scale(0.5) rotate(-90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0deg);
  }
}

@media(max-width:600px) {

  .notice-mobile{
    font-size: 12px;
    padding: 0 5px;
  }

  .light-btn-mobile{
    width: 300px;
    font-size: 12px !important;
}
}

#myProgress {
  width: 100%;
  background-color: #ddd;
  margin-top: 0px;
  border-radius: 10px;
}

#myBar {
  width: 1%;
  height: 5px;
  background-color: #144E82;
  border-radius: 10px;
}

.loader-position {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.clickbutton{
  background-color: white;
  border: 2px solid #144E82;
  color: #144E82;
  font-size: 1.2rem;
  padding: 5px 20px;
  border-radius: 30px;
  font-weight: bold;
  font-family:'Raleway', sans-serif !important;
  outline: none !important;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px;
}
.clickbutton:hover{
  background-color: #144E82;
  color: white;
  border: 2px solid #144E82;

}
.loadingtext{
  margin-top: 10px;
  font-size: 14; 
  text-align: center
}

@media screen and (max-width:850px) and (orientation: landscape) {
  .notice{
    top: 5vh;
    bottom: auto;
  }
}