#bottommenu {
    width: 100%;
    height: 108px;
    color: #fff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    position: fixed;
    z-index: 1000;
    bottom: 0px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: bold;
    background-image: linear-gradient(to right, rgba(20, 78, 130, 0.7), rgba(20, 78, 130, 0.7));
}

.mobmenu {
    display: none;
}

.widthmenuicon {
    display: none;
}

@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {
    .bottomaniamtion {
        transform: translateY(500px);
    }
    .mobmenu {
        display: block;
        position: fixed;
        bottom: 10px;
        left: 20px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        z-index: 12;
        box-shadow: 0 5px 10px 2px rgb(0 0 0 / 30%);
        border-radius: 50%;
    }
    .widthmenuicon {
        width: 40px;
        display: block;
    }
    .mobmenuclose {
        display: none;
        position: fixed;
        bottom: 120px;
        left: 20px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        z-index: 12;
        box-shadow: 0 5px 10px 2px rgb(0 0 0 / 30%);
        border-radius: 50%;
    }
}

.flexicon {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 5px;
    margin-top: 5px;
}

@media(max-width:900px) {
    .flexicon {
        justify-content: start;
        overflow: auto;
    }
}

.widthicon {
    width: 45px;
    margin: 0 auto;
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
}

.grayImage {
    filter: grayscale(1);
    cursor: default !important;
    pointer-events: none;
}

@media(max-width:700px) {
    .widthicon {
        width: 40px;
    }
}

.widthicon:hover {
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
    transform: scale(0.8);
}

.widthicon:active {
    transform: scale(0.8);
}

.flexcolumn {
    display: flex;
    flex-direction: column;
    width: 100px;
    margin: 3px 5px;
    cursor: pointer;
    min-width: 100px;
}

.cenetrtext {
    text-align: center;
    font-size: 12px;
}

.bottomtext {
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif !important;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.startcamerabtn {
    background-color: white;
    color: #000;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    outline: none !important;
    border: 0;
    width: 250px;
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
}

.LABEL {
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
    font-size: 20px;
    color: black;
}

.green-text {
    color: #144E82;
}

.btnBlueGreen {
    z-index: 1001;
    color: white;
    width: 150px;
    background-color: #144E82;
    outline: none !important;
    border: 0;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    font-weight: bold;
    height: 40px;
}

.btnBlueGreen:hover {
    background-color: transparent;
    border: 2px solid #144E82;
    color: #144E82;
}

.margin {
    margin-left: 10px;
}

.active {
    background-color: transparent;
    border: 1px solid #144E82;
    color: #144E82;
    outline: none !important;
}

.agendaImage {
    padding: 0px 30px 24px 30px !important;
    font-size: 13px;
    color: #666;
}

.modal {
    overflow: auto !important;
}

@media screen and (min-width: 320px) and (orientation: landscape) {
    .cenetrtext {
        font-size: 10px;
    }
    .bottomtext {
        margin-top: 8px;
    }
}