.flex__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closevideobtn {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 30px;
    cursor: pointer;
    z-index: 10000;
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
}

.videowidth {
    width: 100%;
    height: 60vh;
}

@media(max-width:600px) {
    .videowidth {
        height: 30vh;
    }
}

#player>video {
    height: 100% !important;
    width: 100% !important;
}