.iframe-position {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
}

.overlayVideo {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1400;
  background-color: rgba(0, 0, 0, 0.8);
}

#video-popup {
  position: fixed;
  z-index: 1500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media(max-width:600px) {
  #video-popup {
    width: 300px;
    height: 309px;
  }
}


#video-popup1 {
  position: fixed;
  z-index: 1500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 420px;
}

#video-popup2 {
  position: fixed;
  z-index: 1500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 420px;
}

@media(max-width:600px) {
  #video-popup1 {
    width: 300px;
    height: 309px;
  }
  #video-popup2 {
    width: 300px;
    height: 309px;
  }
}

.videopopup {
  width: 100%;
  margin-bottom: 10px;
}

.startExperianceBtn {
  z-index: 1001;
  color: white;
  width: 150px;
  background-color: #144E82;
  outline: none !important;
  border: 0;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.8);
  margin-top: 10px;
  font-weight: bold;
  font-family: 'regular', sans-serif !important;
}

.heightExperiance {
  height: 40px;
}

.startExperianceBtn:hover {
  background-color: white;
  border: 2px solid #144E82;
  color: #144E82;
}

.overlayVideo {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1400;
  background-color: rgba(0, 0, 0, 0.8);
}

.playbtnwidth {
  width: 30px;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.8);
  border-radius: 50%;
}

.closebtnposition {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 10000;
  box-shadow: 0 0 10px;
  border-radius: 50%;
}

.videoTitle {

  text-align: center;
  align-self: center;
  top: -50px;
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: underline;
}

.closebtnwidth {
  width: 35px;
}

.playvideotext {
  color: #fff;
  /* text-decoration: underline; */
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 10px;
  font-family: 'regular', sans-serif !important;
}

.btn-glow {
  color: #fff !important;
  display: inline-block;
  background: rgb(20, 78, 130);
  background: -moz-linear-gradient(left, rgba(20, 78, 130, 1) 0%, rgba(235, 31, 78, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(20, 78, 130, 1) 0%, rgba(235, 31, 78, 1) 100%);
  background: linear-gradient(to right, rgba(20, 78, 130, 1) 0%, rgba(235, 31, 78, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8a61f8', endColorstr='#ec5fe7', GradientType=1);
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  /* line-height: 56px; */
  letter-spacing: 0.07em;
  text-decoration: none !important;
  text-transform: uppercase;
  padding: 10px ;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  box-shadow: none;
  animation: glow 3s infinite;
  animation-timing-function: ease-out;
  position: relative;
  /* border-radius: 10px; */
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  z-index: 1000;
}

.btn-glow:hover {
  box-shadow: 0 0 0 10px #3daaa200;
  transition: .8s;
  -webkit-transition: .8s;
  z-index: 1000;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 20px #EB1F4E;
    text-shadow: 0 0 0px #fff;
  }

  40% {
    box-shadow: 0 0 100px #EB1F4E;
    text-shadow: 0 0 20px #fff;
  }

  100% {
    box-shadow: 0 0 20px #EB1F4E;
    text-shadow: 0 0 1px #fff;
  }
}

.btn-hover-shine {
  position: fixed;
  bottom: 108px;
  left: 50%;
  transform: translate(-50%, 0px);
  outline: none !important;
  z-index: 1000;
}

@media(max-width:600px){
  .btn-hover-shine {
    width: 190px;
  }
}


@media screen and (max-width: 900px) and (orientation: landscape) {
  .closebtnposition {
    position: relative;
    top: 10px;
    right: -90px;
  }
  .btn-hover-shine{
    bottom: 0px;
  }
}

@media screen and (min-width: 896px) and (orientation: landscape) {
  .btn-hover-shine{
    bottom: 108px;
  }
}

.btn-hover-shine:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  right: 80%;
  width: 1px;
  background-color: #ffffff00;
  box-shadow: 0 0 20px #ffd2fe00, 0 0 10px #ffd2fe00, 0 0 5px #ffd2fe00, 0 0 2px #ffd2fe00;
  opacity: 1;
  z-index: 1000;
}

.btn-hover-shine:hover:after {
  right: 2%;
  background-color: #ffffffff;
  box-shadow: 0 0 30px #ffd2feff, 0 0 15px #ffd2feff, 0 0 7px #ffd2feff, 0 0 3px #ffd2feff;
  opacity: 0;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  z-index: 1000;
}

/* mobile landscape */
@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {

  .videopopup{
    height: 100px;
  }
  #video-popup1 {
  height: 120px;
  }
}



.showEntranceButtons{
  display: none;
}