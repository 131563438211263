/* --------------------------------------------------------------------- */

/* Entrance Page Header */

/* --------------------------------------------------------------------- */

.logopositionleft {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 200;
}

.widthangleslogo {
  width: 170px !important;
  cursor: pointer;
}

@media(max-width:600px) {
  .widthangleslogo {
    width: 120px !important;
    cursor: pointer;
  }
}

.widthangleslogo1 {
  width: 170px !important;
  ;
  cursor: pointer;
}

.logopositionright {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 200;
}

.widthboihringerlogo {
  width: 140px !important;
  cursor: pointer;
}

@media(max-width:600px) {
  .widthboihringerlogo {
    width: 100px !important;
    cursor: pointer;
  }
}

@media screen and (max-width:1024px) and (orientation: landscape){
  .widthboihringerlogo, .widthangleslogo {
    width: 80px !important;
    cursor: pointer;
  }
}